<template>
  <div class="scl-wrapper" v-if="testimonials.length">
    <div class="title">What do people say about us</div>
    <div class="position-relative mt-4 w-100">
      <div class="buttons position-absolute w-100 d-flex align-items-center justify-content-between">
        <ion-button class="prev"><i class="ti-arrow-left" /></ion-button>
        <ion-button class="next"><i class="ti-arrow-right" /></ion-button>
      </div>
      <ClientOnly>
        <swiper
          :slidesPerView="
            isMobSmallScreen || testimonials.length === 1 ? 1 : !isMobSmallScreen && !isDesktopSize ? 2 : 2
          "
          :showsPagination="false"
          :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
          :navigation="{
            nextEl: '.next',
            prevEl: '.prev',
          }"
          :loop="true"
          :pagination="{ clickable: true }"
          :autoplay="{ delay: 8000, disableOnInteraction: false }"
          class="visuals mt-3"
          :class="{ center: testimonials.length === 1 }"
        >
          <swiper-slide v-for="(test, index) in testimonials" :key="`vis-${index}`">
            <div class="test px-4">
              <div class="title">
                {{ test.title }}
              </div>
              <div class="description mt-3">
                {{ truncateEasy(test.description, 500) }}
              </div>
              <div class="name mt-3">- {{ test.name }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { truncateEasy } from '@/shared/utils/string';
import { getAllTestimonials } from '@/shared/actions/testimonials';
const { isMobSmallScreen, isDesktopSize } = useWindowSize();
const testimonials = ref<any[]>([]);

onMounted(async () => {
  testimonials.value = await getAllTestimonials();
});
</script>
<style lang="sass" scoped>
.title
  font-size: 20px
  font-weight: bold
.buttons
  bottom: 0
  top: 0
  left: 0
  right: 0
  margin: auto
  width: 100%
  z-index: 2
  pointer-events: none
  .prev, .next
    pointer-events: all
    width: 30px
    height: 30px
    --background: rgba(10, 9, 40, 0.9) !important
    border-radius: 20px
    color: #FFF
    --border-radius: 20px
.title
  font-size: 24px
  font-weight: bold
.test
  .title
    font-size: 19px
  *
    user-select: none !important
    @media(max-width: 500px)
      text-align: center
  .name
    font-style: italic
    font-weight: bold
.visuals
  display: flex
  align-items: flex-start
  overflow: hidden !important
  width: calc(100% - 50px)
  margin: auto
  &.center
    ::v-deep
      .swiper-wrapper
        justify-content: center
  @media(max-width: 500px)
    width: calc(100% - 60px)
  ::v-deep
    .swiper-wrapper
      align-items: flex-start !important
    .swiper-pagination-bullets
      display: none !important
    .swiper-wrapper
      align-items: center
    .swiper-slide
      img
        border-radius: 12px
    .swiper-button-prev, .swiper-button-next
      display: none !important
      &:after
        font-size: 16px
        margin-left: -2px
      &:hover
        opacity: 0.8 !important
    .swiper-button-next
      right: -10px
    .swiper-button-prev
      left: -10px
      &:after
        margin-left: 2px !important
</style>
