import { chAxios } from '@/shared/lib/axios';

export async function getAllTestimonials() {
  try {
    const { data } = await chAxios().get(`/testimonials/`);
    return data;
  } catch (e) {
    throw e;
  }
}
